import * as THREE from "three";
import * as CANNON from "cannon";
import Entity from "./Entity.js";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import CollisionGroup from "../Utils/Collision.js";

export default class Sign extends Entity {
  constructor(experience, textTexture) {
    super(
      experience,
      clone(experience.resources.items.sign.scene),
      new CANNON.Body({
        mass: 5000,
        position: new CANNON.Vec3(0, 0.5, 0),
        shape: new CANNON.Box(new CANNON.Vec3(0.1, 0.5, 0.1)),
        type: CANNON.Body.STATIC,
      }),
      new THREE.Vector3(0, -0.5, 0),
      new THREE.Quaternion()
    );
    this.text = new THREE.Mesh(
      new THREE.PlaneGeometry(2.0, 2.0),
      new THREE.MeshBasicMaterial({
        map: textTexture,
        transparent: true,
      })
    );
    this.text.scale.set(0.8, 0.8, 0.8)
    this.text.rotateX(Math.PI / 180.0 * -60)
    this.experience.world.scene.add(this.text)
  }

  update(delta, characterEntity) {
    const characterCoords = new THREE.Vector2(
      characterEntity.body.position.x,
      characterEntity.body.position.z
    );
    const coords = new THREE.Vector2(
      this.model.position.x,
      this.model.position.z
    );

    this.display = coords.distanceTo(characterCoords) < 2.0

    this.text.material.opacity = THREE.MathUtils.lerp(
      this.text.material.opacity,
      this.display ? 1.0 : 0.0,
      delta * 10.0
    );
  }

  sync(delta) {
    super.sync(delta)
    this.text.position.copy(this.body.position.clone().vadd(new THREE.Vector3(0, 0.9, 0)))
  }
}
