import * as THREE from "three";
import * as CANNON from "cannon";
import CollisionGroup from "../Utils/Collision.js";

export default class Entity {
  constructor(
    experience,
    model,
    body,
    positionOffset = new THREE.Vector3(),
    rotationOffset = new THREE.Quaternion()
  ) {
    this.experience = experience;
    this.resources = this.experience.resources;
    this.model = model;
    this.body = body;
    this.body.collisionFilterGroup = CollisionGroup.ENVIRONMENT;
    this.body.collisionFilterMask =
      CollisionGroup.ENVIRONMENT | CollisionGroup.GROUND;
    this.positionOffset = positionOffset;
    this.rotationOffset = rotationOffset;
    this.mixer = new THREE.AnimationMixer(this.model);
  }

  playAnimation(animationName, repeat = true) {
    if (this.lastAnimationName == animationName) return;
    this.lastAnimationName = animationName;
    const clip = THREE.AnimationClip.findByName(
      this.model.animations,
      animationName
    );

    this.mixer.stopAllAction();
    if (clip) {
      const action = this.mixer.clipAction(clip);
      action.setLoop(repeat ? THREE.LoopRepeat : THREE.LoopOnce);
      action.clampWhenFinished = !repeat;
      action.reset().play();
    }
  }

  update(delta) {
    this.mixer.update(delta);
    this.sync(delta)
  }

  sync(delta) {
    this.syncPosition(delta);
    this.syncRotation(delta);
  }

  syncPosition(delta) {
    if (this.model && this.body) {
      const offsetPosition = new THREE.Vector3()
        .copy(this.body.position)
        .add(this.positionOffset);
      this.model.position.copy(offsetPosition);
    }
  }

  syncRotation(delta) {
    if (this.model && this.body) {
      const offsetQuaternion = new THREE.Quaternion()
        .copy(this.body.quaternion)
        .multiply(this.rotationOffset);
      this.model.quaternion.copy(offsetQuaternion);
    }
  }
}
