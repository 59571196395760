import * as THREE from "three";
import * as CANNON from "cannon";
import Entity from "./Entity.js";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import CollisionGroup from "../Utils/Collision.js";

export default class Tent extends Entity {
  constructor(experience) {
    super(
      experience,
      clone(experience.resources.items.tent.scene),
      new CANNON.Body({
        mass: 5000,
        position: new CANNON.Vec3(0, 0.5, 0),
        shape: new CANNON.Box(new CANNON.Vec3(0.35, 0.5, 0.5)),
        type: CANNON.Body.STATIC,
      }),
      new THREE.Vector3(0, -0.5, 0),
      new THREE.Quaternion()
    );
    this.spotLight = new THREE.SpotLight(0xFF5954, 1.0, 7, Math.PI / 1, 0.5, 2);
    this.experience.world.scene.add(this.spotLight);
  }

  update(delta) {
  }

  sync(delta) {
    super.sync(delta)
    const pos = new THREE.Vector3()
      .copy(this.body.position)
      .add(new THREE.Vector3(0, 1, 0).multiplyScalar(-0.25))
  
    this.spotLight.position.copy(pos);
  }
}
