import * as THREE from "three";
import * as CANNON from "cannon";
import Entity from "./Entity.js";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import CollisionGroup from "../Utils/Collision.js";

export default class Character extends Entity {
  constructor(experience, pointlight) {
    const model = clone(experience.resources.items.orc.scene);
    model.animations = experience.resources.items.orc.animations;
    const body = new CANNON.Body({
      mass: 50,
      //type: CANNON.Body.KINEMATIC,
      position: new CANNON.Vec3(0, 2.0, 0),
      //shape: new CANNON.Box(new CANNON.Vec3(0.2, 0.3, 0.2)),
      shape: new CANNON.Sphere(0.25),
    });
    super(
      experience,
      model,
      body,
      new THREE.Vector3(0, -0.25, 0),
      new THREE.Quaternion().setFromAxisAngle(
        new THREE.Vector3(0, 0, 0),
        Math.PI / 2
      )
    );

    this.speed = 125;
    this.target = null;
    this.health = 50;

    this.body.isEnemy = true;

    this.attacks = [
      "attack-melee-right",
      "attack-melee-left",
      "attack-kick-right",
      "attack-kick-left",
    ];
    this.attack = this.attacks[Math.floor(Math.random() * this.attacks.length)];

    this.attackRange = 1.0;
    this.followRange = 0.8;

    this.modifier = 1;

    this.playAnimation("idle");

    this.spotLight = pointlight//
    this.experience.world.scene.add(this.spotLight);
  }

  takeDamage(damage) {
    if (this.died) return;
    this.health -= damage;
    this.died = this.health <= 0.0;
    if (this.died) {
      let toDestroy = false;
      this.experience.world.spawnEnemy(this.spotLight);
      this.spotLight = null
      this.experience.world.planeTexts.forEach((e) => {
        if (this.body.position.clone().distanceTo(e.position) <= 4.0) {
          toDestroy = true;
        }
      });
      this.body.collisionFilterGroup = CollisionGroup.ON_GROUND;
      if (toDestroy) {
        setTimeout(() => {
          this.body.collisionFilterGroup = 0;
        }, 1000)
      }
      this.body.collisionFilterMask = CollisionGroup.GROUND;
      this.body.velocity.x = 0;
      this.body.velocity.z = 0;
      this.body.angularVelocity = new CANNON.Vec3();
    }
  }

  playAnimation(animationName, repeat = true) {
    // Add logic to play specific animations
    super.playAnimation(animationName, repeat);
  }

  setTarget(target) {
    this.target = target;
  }

  update(delta) {
    this.mixer.update(delta);

    this.handleInput(delta);
    this.syncPosition(delta);
    if (this.spotLight) this.spotLight.position.copy(this.body.position);
  }

  handleInput(delta) {
    this.modifier = 1.0;
    if (!this.died) {
      this.handleHeading(delta);
      this.handleMovement(delta);
    } else {
      this.body.velocity.x = 0;
      this.body.velocity.z = 0;
      this.body.angularVelocity = new CANNON.Vec3();
    }
    this.handleAnimation(delta);
  }

  handleMovement(delta) {
    let movement = new CANNON.Vec3(0, 0, 0);
    this.isAttacking = false;
    if (this.target && !this.target.safe) {
    //if (this.target) {
      const distance = this.body.position.distanceTo(this.target.body.position);
      this.isAttacking = distance <= this.attackRange;
      if (distance >= this.followRange) {
        movement = this.target.body.position
          .clone()
          .vsub(this.body.position.clone());
      }
      if (this.isAttacking) {
        this.target.takeDamage(0)
      }
    }
    if (movement.length() > 0) {
      movement.normalize();
    }
    movement = movement.mult(this.speed * this.modifier * delta);
    //this.body.position.vadd(movement)
    this.body.velocity.x = movement.x;
    this.body.velocity.z = movement.z;

    this.isRunning = movement.length() > 0.05;
  }

  handleHeading(delta) {
    if (this.target) {
      this.model.lookAt(this.target.model.position);
    } else {
      const movementDirection = new THREE.Vector3(
        this.body.velocity.x,
        0,
        this.body.velocity.z
      );
      const point = this.model.position.clone().add(movementDirection);
      this.model.lookAt(point);
    }
  }

  handleAnimation(delta) {
    if (this.died) {
      this.playAnimation("die", false);
    } else if (this.isAttacking) {
      this.playAnimation(this.attack);
    } else if (this.isRunning) {
      if (this.modifier > 0.75) {
        this.playAnimation("sprint");
      } else {
        this.playAnimation("walk");
      }
    } else {
      this.playAnimation("idle");
    }
  }
}
