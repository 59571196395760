import * as THREE from "three";
import * as CANNON from "cannon";
import Entity from "./Entity.js";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import CollisionGroup from "../Utils/Collision.js";

export default class Bullet extends Entity {
  constructor(experience) {
    const model = clone(experience.resources.items.bullet.scene);
    const body = new CANNON.Body({
      mass: 1,
      //type: CANNON.Body.
      position: new CANNON.Vec3(0, 0.5, 0),
      shape: new CANNON.Box(new CANNON.Vec3(0.05, 0.05, 0.05 )),
      collisionResponse: false,
      isTrigger: true,
    });
    super(
      experience,
      model,
      body,
      new THREE.Vector3(0, 0.0, 0),
      new THREE.Quaternion().setFromAxisAngle(
        new THREE.Vector3(1, 0, 0),
        Math.PI * 0.5
      )
    );

    this.speed = 400
    this.direction = null

    this.body.addEventListener('collide', event => this.onCollide(event));
  }

  onCollide(event) {
    const collidedBody = event.body;

    if (this.speed <= 0) return

    this.body.collisionFilterGroup = CollisionGroup.ON_GROUND
    this.body.collisionFilterMask = CollisionGroup.GROUND | CollisionGroup.ON_GROUND

    this.speed =0.0

    this.body.mass = 1
    //this.body.shape= new CANNON.Box(new CANNON.Vec3(0.01, 0.01, 0.01 ))

    //this.experience.world.removeEntity(this)
    //this.experience.world.scene.remove(this.model)
    //this.experience.world.physics.removeBody(this.body)
    // Handle the collision
    // For example, check if the collided body is an enemy, apply damage, etc.
    //console.log("Bullet collided with:", collidedBody);

    if (collidedBody.isEnemy) {
      this.experience.world.entities.forEach((entity) => {
        if (entity.body && entity.body == collidedBody) {
          entity.takeDamage(20)
        }
      })
      //collidedBody.velocity.x = this.body.velocity.x * 10
      //sdconsole.log(collidedBody.applyForce(this.body.velocity, this.body.position))
    }

    
    // You can also access the Three.js object if you have linked it with the Cannon.js body
    //const threeObject = collidedBody.threeObject; // Assuming you have a reference to the Three.js object in your Cannon.js body
    //if (threeObject) {
    //  console.log("Collided with Three.js object:", threeObject);
    //}

    // Additional collision handling code here
  }

  playAnimation(animationName) {
    super.playAnimation(animationName);
  }

  update(delta) {
    this.mixer.update(delta);

    this.handleInput(delta);
    super.update(delta)
  }

  handleInput(delta) {
    this.handleMovement(delta);
  }

  handleShooting(delta) {
    
  }

  handleMovement(delta) {
    if (this.speed > 0){
      const forward = new THREE.Vector3(0, 0, 1.0).applyQuaternion(
        this.body.quaternion
      );
  
      let movement = new CANNON.Vec3(forward.x, forward.y, forward.z)
      if (movement.length() > 0) {
        movement.normalize();
      }
      movement = movement.mult(this.speed *  delta);
      this.body.velocity.x = movement.x;
      this.body.velocity.y = 0.0;
      this.body.velocity.z = movement.z;

    }
  }
}
