import * as THREE from "three";
import * as CANNON from "cannon";
import Entity from "./Entity.js";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import CollisionGroup from "../Utils/Collision.js";

export default class Stone extends Entity {
  constructor(experience) {
    super(
      experience,
      clone(experience.resources.items.stones.scene),
      new CANNON.Body({
        mass: 5000,
        position: new CANNON.Vec3(0, 0.25, 0),
        shape: new CANNON.Box(new CANNON.Vec3(0.225, 0.25, 0.225)),
        type: CANNON.Body.STATIC,
      }),
      new THREE.Vector3(0, -0.25, 0),
      new THREE.Quaternion()
    );
    super.sync(0)
  }

  update(delta) {
  }
}
