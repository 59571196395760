import * as THREE from "three";
import * as CANNON from "cannon";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import Experience from "./Experience.js";
import Entity from "./Entities/Entity.js";
import Character from "./Entities/Character.js";
import Enemy from "./Entities/Enemy.js";
import Column from "./Entities/Column.js";
import Tent from "./Entities/Tent.js";
import Campfire from "./Entities/Campfire.js";
import Sign from "./Entities/Sign.js";
import Letter from "./Entities/Letter.js";
import LightPost from "./Entities/LightPost.js";
import Stone from "./Entities/Stone.js";
import Tree from "./Entities/Tree.js";
import CannonDebugger from "cannon-es-debugger";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import InteractiveArea from "./Entities/InteractiveArea.js";
import CollisionGroup from "./Utils/Collision.js";

export default class World {
  constructor(_options) {
    this.loadingPage();
    this.experience = new Experience();
    this.config = this.experience.config;
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.entities = [];
    this.areas = [];
    this.physics = new CANNON.World();
    this.physics.gravity.set(0, -9.81, 0); // Earth's gravity
    this.physics.broadphase = new CANNON.NaiveBroadphase();
    this.physics.solver.iterations = 10;

    this.cannonDebugger = new CannonDebugger(this.scene, this.physics, {
      // options...
    });

    this.maxEnemies = 2;

    this.resources.on("groupEnd", (_group) => {
      if (_group.name === "base") {
        this.createEnvironment();
        this.setDummy();
        this.setLetters();
        this.initializeGame();
        setTimeout(() => {
          this.loadedPage();
        }, 1000);
      }
    });
  }

  loadedPage() {
    const loadingPage = document.getElementById("loading-page");
    loadingPage.style.opacity = "0";
    setTimeout(() => (loadingPage.style.display = "none"), 2000);

    this.characterEntity.playing = true;
    this.experience.camera.switchToGameplayCoords();
    clearInterval(this.intervalId);
  }

  loadingPage() {
    const loadingText = document.getElementById("loading-text");
    let dots = 0;

    this.intervalId = setInterval(() => {
      dots = (dots + 1) % 3;
      loadingText.textContent = ".".repeat(dots + 1);
    }, 500); // Update every 500 milliseconds
  }

  createTextTexture(text, width, height) {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext("2d");

    context.fillStyle = "#FFFFFF";
    context.font = "bold 14px Roboto";
    context.textAlign = "center";
    context.textBaseline = "middle";
    context.fillText(text, width / 2, height / 2);

    return new THREE.CanvasTexture(canvas);
  }

  createTextPlane(text, position, width, height) {
    const textTexture = this.createTextTexture(text, width, height);

    // Apply the text texture to the plane
    const plane = new THREE.Mesh(
      new THREE.PlaneGeometry(10, 10),
      new THREE.MeshBasicMaterial({
        map: textTexture,
        transparent: true,
      })
    );

    plane.rotateX(-Math.PI * 0.5);
    plane.position.x = position.x;
    plane.position.y = 0.1;
    plane.position.z = position.y;

    return plane;
  }

  createDirectionalLight() {
    this.directionalLight = new THREE.DirectionalLight(0xffffff, 0.4);
    this.directionalLight.castShadow = true;
    this.directionalLight.shadow.camera.left = -20;
    this.directionalLight.shadow.camera.right = 20;
    this.directionalLight.shadow.camera.top = 20;
    this.directionalLight.shadow.camera.bottom = -20;
    this.directionalLight.shadow.mapSize.width = 2024;
    this.directionalLight.shadow.mapSize.height = 2024;
    this.directionalLight.shadow.camera.near = 0.5;
    this.directionalLight.shadow.camera.far = 500;
    this.directionalLight.target.position.set(0, 0, 0);
    this.scene.add(this.directionalLight);
    this.scene.add(this.directionalLight.target);
  }

  createAmbientLight() {
    const ambientLight = new THREE.AmbientLight(0x3333ff, 1.0);
    this.scene.add(ambientLight);
  }

  createSpotLight() {
    const spotLight = new THREE.SpotLight(0xff0000, 5.0, 5.0, Math.PI / 5);
    spotLight.position.set(0, 2, 0);
    spotLight.rotateX(Math.PI * 0.2);
  }

  createGround() {
    this.ground = new THREE.Mesh(
      new THREE.PlaneGeometry(500, 500),
      new THREE.MeshStandardMaterial({
        color: 0x2b2c36,
        side: THREE.DoubleSide,
      })
    );
    this.ground.rotateX(Math.PI * -0.5);
    this.ground.receiveShadow = true;
    this.scene.add(this.ground);

    const groundBody = new CANNON.Body({
      mass: 0,
      shape: new CANNON.Plane(),
    });
    groundBody.quaternion.setFromEuler(-Math.PI / 2, 0, 0);

    groundBody.collisionFilterGroup = CollisionGroup.GROUND;
    groundBody.collisionFilterMask =
      CollisionGroup.ENVIRONMENT | CollisionGroup.ON_GROUND;
    this.physics.addBody(groundBody);

    const paths = [
      {
        pos: new THREE.Vector2(-1.5708957275519957, 2.522430059325663),
        rot: 251,
      },
      {
        pos: new THREE.Vector2(-0.9408038028834353, 3.650382982817717),
        rot: 229,
      },
      { pos: new THREE.Vector2(9.078, -1.815), rot: 75 },
      { pos: new THREE.Vector2(10.465, -2.0909), rot: 179 },
      { pos: new THREE.Vector2(14.078, -2.015), rot: 75 },
      { pos: new THREE.Vector2(15.465, -2.2909), rot: 179 },
      { pos: new THREE.Vector2(19.078, -2.215), rot: 75 },
      { pos: new THREE.Vector2(20.465, -2.4909), rot: 179 },
      //{ pos: new THREE.Vector2(24.078, -2.415), rot: 75 },
      //{ pos: new THREE.Vector2(25.465, -2.6909), rot: 179 },
      //{ pos: new THREE.Vector2(29.078, -2.615), rot: 75 },
      //{ pos: new THREE.Vector2(30.465, -2.8909), rot: 179 },

      { pos: new THREE.Vector2(0, 0), rot: 132.0 },
      { pos: new THREE.Vector2(-0.2, 1), rot: 40.0 },
      { pos: new THREE.Vector2(-0.25, 2), rot: 140.0 },
      { pos: new THREE.Vector2(0.2, 3.25), rot: 280.0 },
      { pos: new THREE.Vector2(-0.15, 4.75), rot: 160.0 },
      { pos: new THREE.Vector2(0.05, 5.5), rot: 60.0 },

      { pos: new THREE.Vector2(1.73, -1.5), rot: 60.0 },
      { pos: new THREE.Vector2(2.325, -2.032), rot: 80.0 },
      { pos: new THREE.Vector2(3.533, -2.247), rot: 100.0 },
      {
        pos: new THREE.Vector2(5.156083532056371, -1.9226031856161154),
        rot: 100.0,
      },
      { pos: new THREE.Vector2(-1.9, -0.72), rot: 32.0 },
      { pos: new THREE.Vector2(-2.85, -1.12), rot: 140.0 },
      { pos: new THREE.Vector2(-4.65, -1.3), rot: 50.0 },
      { pos: new THREE.Vector2(-5.4, -0.91), rot: 160.0 },
      {
        pos: new THREE.Vector2(-6.758773993908242, -1.29430070046568),
        rot: 3.0,
      },
      {
        pos: new THREE.Vector2(-7.626447341262927, -1.111073914224953),
        rot: 80.0,
      },

      { pos: new THREE.Vector2(-0.805, -2.343), rot: 280 },
      {
        pos: new THREE.Vector2(-1.803587872852872, 0.7712083415771647),
        rot: 124,
      },
    ];
    paths.forEach((path) => {
      const model = clone(this.resources.items.path.scene);
      this.scene.add(model);
      model.position.x = path.pos.x;
      model.position.z = path.pos.y;
      model.rotation.y = (path.rot * Math.PI) / 180.0;
    });
  }

  createEnvironment() {
    this.createDirectionalLight();
    this.createAmbientLight();
    this.createGround();
  }

  createAreas() {
    const texts = [
      { textureKey: "textSocials", position: { x: 1.5, z: 5.95 }, size: new THREE.Vector2(2.0, 2.0) },
      { textureKey: "gitlab", position: { x: 0, z: 7.55 }, size: new THREE.Vector2(2.0, 2.0) },
      { textureKey: "itch", position: { x: 1.5, z: 7.55 }, size: new THREE.Vector2(2.0, 2.0) },
      { textureKey: "mail", position: { x: -1.5, z: 7.55 }, size: new THREE.Vector2(2.0, 2.0) },
      { textureKey: "textStudies", position: { x: -2.0, z: 5.0 }, size: new THREE.Vector2(2.0, 2.0) },
      { textureKey: "textRoomsLinked", position: { x: 7.406, z: -3.462 }, size: new THREE.Vector2(4.0, 2.0) },
      { textureKey: "textSwissQR", position: { x: 12.406, z: -3.662 }, size: new THREE.Vector2(4.0, 2.0) },
      { textureKey: "textLSC1", position: { x: 17.406, z: -3.862 }, size: new THREE.Vector2(4.0, 2.0) },
      //{ textureKey: "gitlab", position: { x: 22.406, z: -4.062 }, size: new THREE.Vector2(2.0, 2.0) },
      {
        textureKey: "textControls",
        position: { x: -8.407, z: 0.859 },
        size: new THREE.Vector2(2.0, 2.0),
      },
    ];

    const panels = [
      {
        pos: new THREE.Vector2(-1.5, 6.8),
        size: new THREE.Vector2(1, 1),
        texture: this.resources.items.panelTexture,
        title: "MAIL",
        caption: "Contact me karim.kummer@bluewin.ch",
        url: "mailto:karim.kummer@bluewin.ch",
      },
      {
        pos: new THREE.Vector2(0.0, 6.8),
        size: new THREE.Vector2(1, 1),
        texture: this.resources.items.panelTexture,
        title: "GITLAB",
        caption: "Visit some Gitlab projects",
        url: "https://gitlab.com/Kak-s",
      },
      {
        pos: new THREE.Vector2(1.5, 6.8),
        size: new THREE.Vector2(1, 1),
        texture: this.resources.items.panelTexture,
        title: "ITCH",
        caption: "Try some mini games I made",
        url: "https://kaks.itch.io",
      },
      {
        pos: new THREE.Vector2(7.406, -1.862),
        size: new THREE.Vector2(2, 1),
        texture: this.resources.items.panelStretchTexture,
        title: "Rooms Linked",
        caption: "Check this project on Gitlab",
        url: "https://gitlab.com/Kak-s/rooms-linked"
      },
      {
        pos: new THREE.Vector2(12.406, -2.062),
        size: new THREE.Vector2(2, 1),
        texture: this.resources.items.panelStretchTexture,
        title: "Swiss QR Bills",
        caption: "Try this application",
        url: "qrbills.kaks.ch"
      },
      {
        pos: new THREE.Vector2(17.406, -2.262),
        size: new THREE.Vector2(2, 1),
        texture: this.resources.items.panelStretchTexture,
        title: "LS Confidential",
        caption: "Check out the Discord server",
        url: "https://discord.com/invite/ls-confidential-581295720204730374",
      },
      //{
      //  pos: new THREE.Vector2(22.406, -2.462),
      //  size: new THREE.Vector2(1, 1),
      //  texture: this.resources.items.panelTexture,
      //  title: "7",
      //  caption: "7",
      //},
    ];

    

    this.createMeshesFromData(texts);
    this.createPanels(panels);
  }

  createPanels(panels) {
    panels.forEach((panel) => {
      const interactiveArea = new InteractiveArea(
        this.experience,
        panel.texture,
        panel.size,
        new THREE.Vector3(0, 0, 0),
        panel.title,
        panel.caption,
        panel.url
      );
      interactiveArea.model.position.x = panel.pos.x;
      interactiveArea.model.position.y = 0.15;
      interactiveArea.model.position.z = panel.pos.y;
      this.addArea(interactiveArea);
    });
  }

  createMeshesFromData(texts) {
    this.planeTexts = [];
    texts.forEach((text) => {
      const obj = new THREE.Mesh(
        new THREE.PlaneGeometry(text.size.x, text.size.y),
        new THREE.MeshBasicMaterial({
          map: this.resources.items[text.textureKey],
          transparent: true,
        })
      );
      obj.rotateX(-Math.PI * 0.5);
      obj.position.copy(
        new THREE.Vector3(text.position.x, 0.1, text.position.z)
      );
      this.planeTexts.push(obj);
      this.scene.add(obj);
    });
  }

  initializeGame() {
    if (this.init) return;
    for (let i = 0; i < this.maxEnemies; i++) {
      const pointlight = new THREE.PointLight(0x45e863, 2.0, 1.0);
      pointlight.position.y = -50.0;
      this.scene.add(pointlight);

      setTimeout(() => {
        this.spawnEnemy(pointlight);
      }, 4000 * i);
    }
  }

  spawnEnemy(pointlight) {
    let enemyEntity = new Enemy(this.experience, pointlight);
    enemyEntity.setTarget(this.characterEntity);
    const offsetDistance = 30;
    const angle = Math.random() * Math.PI * 2;

    enemyEntity.body.position.x =
      this.characterEntity.body.position.x + offsetDistance * Math.cos(angle);
    enemyEntity.body.position.z =
      this.characterEntity.body.position.z + offsetDistance * Math.sin(angle);

    this.addEntity(enemyEntity);
  }

  setLetters() {
    /*
    console.log(this.resources.items.roboto)
    const textGeometry = new TextGeometry('K', {
      font: this.resources.items.roboto,
      size: 1.0 / 2.0,
      height: 0.2 / 2.0,
      curveSegments: 12,
      bevelEnabled: true,
      bevelThickness: 0.03,
      bevelSize: 0.02,
      bevelOffset: 0,
      bevelSegments: 5
    });

    const textMaterial = new THREE.MeshStandardMaterial({ color: 0xffffff });
    const textMesh = new THREE.Mesh(textGeometry, textMaterial);

    textMesh.position.x = -4.333
    textMesh.position.z = -3.832
    //-4.333228753286025, -3.8323828663191275

    this.scene.add(textMesh)
    */
  }

  setDummy() {
    this.createAreas();

    this.characterEntity = new Character(this.experience);
    this.characterEntity.body.position.x = -8.407;
    this.characterEntity.body.position.z = -0.759;
    this.addEntity(this.characterEntity);
    this.experience.camera.setTarget(this.characterEntity.model);

    const columns = [
      {
        pos: new THREE.Vector2(-5.5499341105277855, -3.299541466275416),
        rot: 87,
      },
      {
        pos: new THREE.Vector2(-1.0196254640460722, -3.37871532068774),
        rot: 23,
      },
      {
        pos: new THREE.Vector2(1.927, -2.974),
        rot: 87,
      },
      {
        pos: new THREE.Vector2(2.882, -0.381),
        rot: 67,
      },
      { pos: new THREE.Vector2(9.189989176361857, -5.090915522418669), rot: 304 },
      {
        pos: new THREE.Vector2(25.136, -3.429),
        rot: 279,
      },
      {
        pos: new THREE.Vector2(22.521, 0.772),
        rot: 168,
      },
      {
        pos: new THREE.Vector2(15.471, -0.505),
        rot: 317,
      },
    ];

    const tents = [
      {
        pos: new THREE.Vector2(-11.523, -0.199),
        rot: 120,
      },
      { pos: new THREE.Vector2(21.33781909939174, -0.6831794976884467), rot: 35 },
    ];
    const campfires = [
      {
        pos: new THREE.Vector2(-9.882186174948457, -0.8680123500736108),
        rot: 143,
      },
    ];

    const signs = [
      {
        pos: new THREE.Vector2(-8.407, -1.594),
        rot: -7,
        args: this.resources.items.textWelcome,
      },
      {
        pos: new THREE.Vector2(-1.039327108544734, 0.4924241638737631),
        rot: -84,
        args: this.resources.items.textInformation,
      },
      //{ pos: new THREE.Vector2(-1.74, 0.3), rot: 183, args: this.resources.items.textInformation, miror: true, },
      //{ pos: new THREE.Vector2(1.1, 1.0), rot: -84, args: this.resources.items.textSocials },
      {
        pos: new THREE.Vector2(-0.0054892356167465205, -1.4496427118134452),
        rot: 40,
        args: this.resources.items.textProjects,
      },
    ];

    const stones = [
      {
        pos: new THREE.Vector2(-1.8540486036805015, 9.268013767646867),
        rot: 263,
      },
      {
        pos: new THREE.Vector2(3.764958427305605, 7.814843648288298),
        rot: 306,
      },
      {
        pos: new THREE.Vector2(7.675457303115571, 1.0637709977565073),
        rot: 161,
      },
      {
        pos: new THREE.Vector2(25.36543392484482, -0.983033802658645),
        rot: 144,
      },
      {
        pos: new THREE.Vector2(20.48493374208157, -6.886330794514081),
        rot: 202,
      },
      {
        pos: new THREE.Vector2(6.866529666657072, -5.279516105087837),
        rot: 100,
      },

      {
        pos: new THREE.Vector2(0.7911142202230163, -4.778450245361078),
        rot: 24,
      },
      {
        pos: new THREE.Vector2(-5.102198419689259, 2.3156301547817013),
        rot: 47,
      },
      {
        pos: new THREE.Vector2(-11.726902668135589, -3.617228803478766),
        rot: 127,
      },
      {
        pos: new THREE.Vector2(-11.989147846379895, 1.800198778944843),
        rot: 202,
      },
    ];

    const lightPosts = [
      {
        pos: new THREE.Vector2(-3.3480309262366985, -5.464117125789737),
        rot: 0,
      },
      {
        pos: new THREE.Vector2(-1.5791613644637037, -1.3444488461383284),
        rot: 45,
      },
      {
        pos: new THREE.Vector2(1.3380977805975087, 3.8842454691576798),
        rot: -40,
      },
      {
        pos: new THREE.Vector2(-5.395731853818934, 0.4974630169915013),
        rot: 160,
      },
      {
        pos: new THREE.Vector2(9.596613675274874, -0.5648489982132234),
        rot: 175,
      },
      { pos: new THREE.Vector2(18.88720686808237, -5.586870396641445), rot: -35 },
    ];

    const trees = [
      {
        pos: new THREE.Vector2(-3.880141062676463, 4.848576552805053),
        rot: 121,
      },
      {
        pos: new THREE.Vector2(-4.1150706245203805, 7.54746962858053),
        rot: 159,
      },
      {
        pos: new THREE.Vector2(1.087174953965369, 9.823935558741729),
        rot: 156,
      },
      {
        pos: new THREE.Vector2(4.340141627080344, 5.868466568505071),
        rot: 113,
      },
      {
        pos: new THREE.Vector2(7.528294613779591, 3.3095579283505816),
        rot: 122,
      },
      {
        pos: new THREE.Vector2(-10.140947838070485, -3.9436627221983342),
        rot: 44,
      },
      {
        pos: new THREE.Vector2(-13.364043752218628, -1.5724630123703598),
        rot: 18,
      },
      {
        pos: new THREE.Vector2(-9.824419572689601, 3.05917676626524),
        rot: 141,
      },
      {
        pos: new THREE.Vector2(-6.364077615436423, -4.253348523721185),
        rot: 351,
      },
      {
        pos: new THREE.Vector2(-0.7735118938644507, -4.774126457980069),
        rot: 50,
      },
      {
        pos: new THREE.Vector2(2.47241786342981, -3.9926342986719687),
        rot: 333,
      },
      {
        pos: new THREE.Vector2(5.220522433673573, 0.5881425731179005),
        rot: 131,
      },
      {
        pos: new THREE.Vector2(4.735744154081154, 1.7210546730594853),
        rot: 333,
      },
      {
        pos: new THREE.Vector2(2.4469397869269707, 3.443765868977451),
        rot: 78,
      },
      {
        pos: new THREE.Vector2(-3.156216833093744, 2.9822278847216053),
        rot: 308,
      },
      {
        pos: new THREE.Vector2(-6.429802970341863, 2.0397085818483305),
        rot: 64,
      },
      {
        pos: new THREE.Vector2(-3.924696178040059, 0.9811457569811486),
        rot: 142,
      },

      {
        pos: new THREE.Vector2(12.432163037236885, 0.434625267465758),
        rot: 357,
      },
      {
        pos: new THREE.Vector2(19.18616380648024, 0.2369226194364415),
        rot: 180,
      },
      {
        pos: new THREE.Vector2(25.039342436503958, -6.579666203396153),
        rot: 234,
      },
      {
        pos: new THREE.Vector2(16.97881103947939, -7.381033375576938),
        rot: 317,
      },
      {
        pos: new THREE.Vector2(13.634170626206936, -6.071602717307691),
        rot: 309,
      },
      {
        pos: new THREE.Vector2(4.17523860648911, -4.059842399763096),
        rot: 336,
      },
      { pos: new THREE.Vector2(22.21494793257154, -4.112255099714152), rot: 327 },
       { pos: new THREE.Vector2(10.958459326926194, -6.777499943980037), rot: 334 },
    ];

    const letters = [
      {
        height: 1.0,
        pos: new THREE.Vector2(-4.333, -3.832),
        rot: 0,
        args: "K",
      },
      {
        height: 1.0,
        pos: new THREE.Vector2(-3.833, -3.832),
        rot: 4,
        args: "A",
      },
      {
        height: 1.0,
        pos: new THREE.Vector2(-3.333, -3.832),
        rot: 2,
        args: "R",
      },
      {
        height: 1.0,
        pos: new THREE.Vector2(-2.833, -3.832),
        rot: -1,
        args: "I",
      },
      {
        height: 1.0,
        pos: new THREE.Vector2(-2.333, -3.832),
        rot: -3,
        args: "M",
      },

      {
        height: 1.0,
        pos: new THREE.Vector2(-4.583, -2.832),
        rot: 2,
        args: "K",
      },
      {
        height: 1.0,
        pos: new THREE.Vector2(-4.083, -2.832),
        rot: -2,
        args: "U",
      },
      {
        height: 1.0,
        pos: new THREE.Vector2(-3.583, -2.832),
        rot: -3,
        args: "M",
      },
      {
        height: 1.0,
        pos: new THREE.Vector2(-3.083, -2.832),
        rot: 0,
        args: "M",
      },
      {
        height: 1.0,
        pos: new THREE.Vector2(-2.583, -2.832),
        rot: 2,
        args: "E",
      },
      {
        height: 1.0,
        pos: new THREE.Vector2(-2.083, -2.832),
        rot: 3,
        args: "R",
      },
    ];

    this.createEntities(lightPosts, LightPost);
    this.createEntities(columns, Column);
    this.createEntities(tents, Tent);
    this.createEntities(campfires, Campfire);
    this.createEntities(signs, Sign);
    this.createEntities(stones, Stone);
    this.createEntities(trees, Tree);
    this.createEntities(letters, Letter);
  }

  createEntities(entities, EntityClass) {
    entities.forEach((entity) => {
      const prop = new EntityClass(this.experience, entity.args);
      prop.body.position.x = entity.pos.x;
      if (entity.height) {
        prop.body.position.y = entity.height;
      }
      prop.body.position.z = entity.pos.y;
      const quaternion = new THREE.Quaternion();
      quaternion.setFromAxisAngle(
        new THREE.Vector3(0, 1, 0),
        entity.rot * (Math.PI / 180)
      );
      prop.body.quaternion.copy(quaternion);
      this.addEntity(prop);
      prop.sync(0);
    });
  }

  addArea(area) {
    this.scene.add(area.model);
    this.areas.push(area);
  }

  addEntity(entity) {
    this.physics.addBody(entity.body);
    this.scene.add(entity.model);
    this.entities.push(entity);
  }

  removeEntity(entity) {
    if (entity.body) {
      this.physics.removeBody(entity.body);
    }
    if (entity.model) {
      this.scene.remove(entity.model);
    }
    const index = this.entities.indexOf(entity);
    if (index !== -1) {
      this.entities.splice(index, 1);
    }
  }

  resize() {}

  update() {
    const delta = Math.min(this.experience.clock.getDelta(), 0.3);
    this.physics.step(1.0 / 60.0, delta, 3);
    //this.cannonDebugger.update();
    for (let i = 0; i < this.entities.length; i++) {
      const entity = this.entities[i];
      entity.update(delta, this.characterEntity);
    }
    if (this.characterEntity) {
      this.areas.forEach((area) => {
        area.update(delta, this.characterEntity);
      });
      this.directionalLight.position.copy(
        this.characterEntity.model.position
          .clone()
          .add(new THREE.Vector3(2, 5, 2))
      );
      this.directionalLight.target.position.copy(
        this.characterEntity.model.position.clone()
      );
    }
  }

  destroy() {}
}
