import * as THREE from "three";
import * as CANNON from "cannon";
import Entity from "./Entity.js";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import CollisionGroup from "../Utils/Collision.js";

export default class LightPost extends Entity {
  constructor(experience, textTexture) {
    super(
      experience,
      clone(experience.resources.items.lightpost.scene),
      new CANNON.Body({
        mass: 5000,
        position: new CANNON.Vec3(0, 0.5, 0),
        shape: new CANNON.Box(new CANNON.Vec3(0.1, 0.5, 0.1)),
        type: CANNON.Body.STATIC,
      }),
      new THREE.Vector3(0, -0.5, 0),
      new THREE.Quaternion()
    );
    this.spotLight = new THREE.SpotLight(0xD8D984, 2.5, 7, Math.PI / 1, 0.5, 2);
    this.experience.world.scene.add(this.spotLight);
  }

  update(delta, characterEntity) {
  }

  sync(delta) {
    super.sync(delta)
    const forward = new THREE.Vector3(0, 0, 1.0).applyQuaternion(
      this.model.quaternion
    );
    const pos = new THREE.Vector3()
      .copy(this.body.position)
      .add(new THREE.Vector3(0, 1, 0).multiplyScalar(0.75))
      .add(forward.multiplyScalar(0.5));

    this.spotLight.position.copy(pos);
  }
}
