export default [
  {
    name: "base",
    data: {},
    items: [
      {
        name: "textStudies",
        source: "/assets/textures/text-studies.png",
        type: "texture",
      },
      {
        name: "textControls",
        source: "/assets/textures/text-controls.png",
        type: "texture",
      },
      {
        name: "textInformation",
        source: "/assets/textures/text-information.png",
        type: "texture",
      },
      {
        name: "textLSC1",
        source: "/assets/textures/text-lsc1.png",
        type: "texture",
      },
      {
        name: "textRoomsLinked",
        source: "/assets/textures/text-roomslinked.png",
        type: "texture",
      },
      {
        name: "textSwissQR",
        source: "/assets/textures/text-swissqr.png",
        type: "texture",
      },
      {
        name: "textProjects",
        source: "/assets/textures/text-projects.png",
        type: "texture",
      },
      {
        name: "textSocials",
        source: "/assets/textures/text-socials.png",
        type: "texture",
      },
      {
        name: "textWelcome",
        source: "/assets/textures/text-welcome.png",
        type: "texture",
      },
      {
        name: "lennaTexture",
        source: "/assets/textures/lenna.png",
        type: "texture",
      },
      {
        name: "panelTexture",
        source: "/assets/textures/panel.png",
        type: "texture",
      },
      {
        name: "gitlab",
        source: "/assets/textures/text-gitlab.png",
        type: "texture",
      },
      {
        name: "mail",
        source: "/assets/textures/text-mail.png",
        type: "texture",
      },
      {
        name: "itch",
        source: "/assets/textures/text-itch.png",
        type: "texture",
      },
      {
        name: "panelStretchTexture",
        source: "/assets/textures/panel-stretch.png",
        type: "texture",
      },
      {
        name: "panelLargeTexture",
        source: "/assets/textures/panel-large.png",
        type: "texture",
      },
      {
        name: "footstep",
        source: "/assets/audios/footstep.ogg",
        type: "ogg",
      },
      {
        name: "shoot",
        source: "/assets/audios/shoot.ogg",
        type: "ogg",
      },
      {
        name: "ouch",
        source: "/assets/audios/ouch.ogg",
        type: "ogg",
      },
      {
        name: "roboto",
        source: "/assets/fonts/roboto-mono.json",
        type: "ttf",
      },
      {
        name: "character",
        source: "/assets/models/character-human.glb",
        type: "glb",
        scale: 1.0,
        shadow: true,
      },
      {
        name: "orc",
        source: "/assets/models/character-orc.glb",
        type: "glb",
        scale: 1.0,
        shadow: true,
      },
      {
        name: "sign",
        source: "/assets/models/sign.glb",
        type: "glb",
        scale: 1.0,
        shadow: true,
      },
      {
        name: "tent",
        source: "/assets/models/tent.glb",
        type: "glb",
        scale: 1.2,
        shadow: true,
      },
      {
        name: "campfire",
        source: "/assets/models/campfire.glb",
        type: "glb",
        scale: 1.2,
        shadow: true,
      },
      {
        name: "weapon",
        source: "/assets/models/weapon.glb",
        type: "glb",
        scale: 1.0,
        shadow: true,
      },
      {
        name: "bullet",
        source: "/assets/models/bullet.glb",
        type: "glb",
        scale: 1.0,
        shadow: true,
      },
      {
        name: "floor",
        source: "/assets/models/floor.gltf",
        type: "gltf",
        scale: 1.0,
        shadow: true,
      },
      {
        name: "path",
        source: "/assets/models/path.glb",
        type: "glb",
        scale: 1.0,
        shadow: true,
      },
      {
        name: "lightpost",
        source: "/assets/models/lightpost.glb",
        type: "glb",
        scale: 1.0,
        shadow: true,
      },
      {
        name: "barrel",
        source: "/assets/models/barrel.glb",
        type: "glb",
        scale: 1.0,
        shadow: true,
      },
      {
        name: "tree",
        source: "/assets/models/tree.glb",
        type: "glb",
        scale: 1.0,
        shadow: true,
      },
      {
        name: "column",
        source: "/assets/models/column.glb",
        type: "glb",
        scale: 1.0,
        shadow: true,
      },
      {
        name: "stones",
        source: "/assets/models/stones.glb",
        type: "glb",
        scale: 1.0,
        shadow: true,
      },
    ],
  },
];
