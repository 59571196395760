import * as THREE from "three";
import * as CANNON from "cannon";
import Entity from "./Entity.js";
import Bullet from "./Bullet.js";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import CollisionGroup from "../Utils/Collision.js";

export default class Character extends Entity {
  constructor(experience) {
    const model = clone(experience.resources.items.character.scene);
    model.animations = experience.resources.items.character.animations;
    const body = new CANNON.Body({
      mass: 10,
      position: new CANNON.Vec3(0, 2.0, 0),
      shape: new CANNON.Sphere(0.15),
    });
    super(
      experience,
      model,
      body,
      new THREE.Vector3(0, -0.15, 0),
      new THREE.Quaternion().setFromAxisAngle(
        new THREE.Vector3(0, 0, 0),
        Math.PI / 2
      )
    );
    this.audios = {
      footstep: {
        audio: this.experience.resources.items.footstep,
        pendingTime: 0,
        duration: () => Math.random() * (0.3 - 0.25) + 0.25,
      },
      shoot: {
        audio: this.experience.resources.items.shoot,
        pendingTime: 0,
        duration: () => 0.25,
      },
      ouch: {
        audio: this.experience.resources.items.ouch,
        pendingTime: 0,
        duration: () => Math.random() * (0.7 - 0.4) + 0.4,
      },
    };

    this.addWeapons();

    this.speed = 200;

    this.mute = false
    this.modifier = 1;
    this.opacity = 1.0;

    this.shootingProgressionDuration = 0.25;
    this.shootingProgressionTime = 0.0;

    this.keys = {};
    this.mouse = {};

    this.playing = false

    window.addEventListener("keydown", (event) => this.onKeyDown(event));
    window.addEventListener("keyup", (event) => this.onKeyUp(event));
    window.addEventListener("mousemove", (event) => this.onMouseMove(event));
    window.addEventListener("mousedown", (event) => this.onMouseDown(event));
    window.addEventListener("mouseup", (event) => this.onMouseUp(event));

    this.switchSafeMode(true)
    this.playAnimation("sit");
  }

  takeDamage() {
      this.playAudio("ouch")
  }

  addWeapons() {
    this.weapons = [
      clone(this.experience.resources.items.weapon.scene),
      //clone(this.experience.resources.items.weapon.scene),
    ];

    this.bones = [null, null];

    var update = THREE.Bone.prototype.update;
    THREE.Bone.prototype.update = function (parentSkinMatrix, forceUpdate) {
      update.call(this, parentSkinMatrix, forceUpdate);
      this.updateMatrixWorld(true);
    };

    THREE.Object3D.prototype.update = function () {};

    this.weapons.forEach((weapon) => {
      this.experience.scene.add(weapon);
    });

    this.model.traverse((child) => {
      if (child.name == "arm-right" && this.weapons[0]) {
        this.weapons[0].position.set(-0.35, 0.125, 0.15);
        this.weapons[0].rotation.set(0, Math.PI * -0.325, 0.0);
        this.weapons[0].scale.set(1, 1, 1);
        child.add(this.weapons[0]);
        this.bones[0] = child;
      }
      if (child.name == "arm-left" && this.weapons[1]) {
       this.weapons[1].position.set(0.35, 0.125, 0.15);
       this.weapons[1].rotation.set(0, -Math.PI * -0.325, 0.0);
       this.weapons[1].scale.set(1, 1, 1);
       child.add(this.weapons[1]);
       this.bones[1] = child;
      }
    });
  }

  onMouseDown(event) {
    if (!this.playing) return
    if (event.button === 0) {
      this.keys.shootMouse = true;
      this.switchSafeMode(false)
    }
  }

  onMouseUp(event) {
    if (!this.playing) return
    if (event.button === 0) {
      this.keys.shootMouse = false;
    }
  }

  onMouseMove(event) {
    if (!this.playing) return
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  }

  onKeyDown(event) {
    if (!this.playing) return
    switch (event.code) {
      case "KeyM":
        this.mute = !this.mute
        break
        //console.log(`{ pos: new THREE.Vector2(${this.body.position.x}, ${this.body.position.z}), rot: ${Math.floor(Math.random() * (360 - 0 + 1) + 0)} }`)
      case "Enter":
        if (this.onArea) {
          window.open(this.onArea.url, '_blank');
          this.switchSafeMode(true)
        }
        break
      case "Space":
        this.keys.shoot = true;
        this.switchSafeMode(false)
        break;
      case "ArrowUp":
      case "KeyW":
        this.keys.forward = true;
        this.switchSafeMode(false)
        break;
      case "ArrowLeft":
      case "KeyA":
        this.keys.left = true;
        this.switchSafeMode(false)
        break;
      case "ArrowDown":
      case "KeyS":
        this.keys.backward = true;
        this.switchSafeMode(false)
        break;
      case "ArrowRight":
      case "KeyD":
        this.keys.right = true;
        this.switchSafeMode(false)
        break;
    }
  }

  onKeyUp(event) {
    if (!this.playing) return
    switch (event.code) {
      case "Space":
        this.keys.shoot = false;
        break;
      case "ArrowUp":
      case "KeyW":
        this.keys.forward = false;
        break;
      case "ArrowLeft":
      case "KeyA":
        this.keys.left = false;
        break;
      case "ArrowDown":
      case "KeyS":
        this.keys.backward = false;
        break;
      case "ArrowRight":
      case "KeyD":
        this.keys.right = false;
        break;
    }
  }

  switchSafeMode(newMode) {
    this.safe = newMode
  }

  playAnimation(animationName) {
    super.playAnimation(animationName);
  }

  update(delta) {
    this.mixer.update(delta);

    Object.entries(this.audios).forEach(([key, value]) => {
      this.audios[key].pendingTime -= delta;
    });

    this.handleInput(delta);
    this.syncPosition(delta);

    this.opacity = THREE.MathUtils.lerp(
      this.opacity,
      this.onArea ? 0.25 : 1.0,
      delta * 10.0
    );

    const size = this.opacity - 0.25 / 0.75;
  }

  handleInput(delta) {
    this.modifier = 1.0;
    this.handleHeading(delta);
    this.handleShooting(delta);
    this.handleMovement(delta);
    this.handleAnimation(delta);
  }

  handleShooting(delta) {
    this.isShooting = false;
    if (this.keys) {
      if (this.keys.shoot || this.keys.shootMouse) {
        this.isShooting = true;
        this.modifier = 0.25;
      }
    }

    this.shootingProgressionTime -= delta;

    if (this.isShooting && this.shootingProgressionTime <= 0.0) {
      this.shootingProgressionTime = this.shootingProgressionDuration;
      let hand = -1;
      this.weapons.forEach((weapon) => {
        const bulletEntity = new Bullet(this.experience);
        const forward = new THREE.Vector3(0, 0, 1.0).applyQuaternion(
          this.model.quaternion
        );
        const right = new THREE.Vector3()
          .copy(forward)
          .applyAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI / 2);
        const pos = new THREE.Vector3()
          .copy(this.body.position)
          .add(new THREE.Vector3(0, 1, 0).multiplyScalar(0.3))
          .add(forward.multiplyScalar(0.25))
          .add(right.multiplyScalar(0.14 * hand));
        bulletEntity.body.position.copy(pos);
        bulletEntity.model.position.copy(pos);
        bulletEntity.body.quaternion.copy(this.model.quaternion);
        this.experience.world.addEntity(bulletEntity);
        hand *= -1.0;
      });
    }
  }

  handleMovement(delta) {
    let movement = new CANNON.Vec3(0, 0, 0);
    if (this.keys) {
      if (this.keys.backward) {
        movement = movement.vadd(new CANNON.Vec3(0, 0, 1));
      }
      if (this.keys.forward) {
        movement = movement.vadd(new CANNON.Vec3(0, 0, -1));
      }
      if (this.keys.right) {
        movement = movement.vadd(new CANNON.Vec3(1, 0, 0));
      }
      if (this.keys.left) {
        movement = movement.vadd(new CANNON.Vec3(-1, 0, 0));
      }
    }
    if (movement.length() > 0) {
      movement.normalize();
    }
    movement = movement.mult(this.speed * this.modifier * delta);
    this.body.velocity.x = movement.x;
    this.body.velocity.z = movement.z;
    this.isRunning = movement.length() > 0.05;
  }

  handleHeading(delta) {
    if (this.keys.shoot || this.keys.shootMouse) {
      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(this.mouse, this.experience.camera.instance);
      const plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
      const intersectionPoint = new THREE.Vector3();
      raycaster.ray.intersectPlane(plane, intersectionPoint);
      const direction = intersectionPoint
        .clone()
        .sub(this.body.position)
        .normalize();
      intersectionPoint.y = this.model.position.y;
      const movementDirection = new THREE.Vector3(direction.x, 0, direction.z);
      const point = this.model.position.clone().add(movementDirection);
      this.model.lookAt(point);
    } else {
      const move = new THREE.Vector2(
        this.body.velocity.x,
        this.body.velocity.z
      );
      if (move.length() > 0.5) {
        const movementDirection = new THREE.Vector3(
          this.body.velocity.x,
          0,
          this.body.velocity.z
        );
        const point = this.model.position.clone().add(movementDirection);
        this.model.lookAt(point);
      }
    }
  }

  playAudio(name) {
    if (this.mute) return
    const audio = this.audios[name];
    if (!audio) return;
    if (audio.pendingTime > 0.0) return;
    const finalAudio = new THREE.Audio(this.experience.camera.audio);
    finalAudio.setBuffer(audio.audio);
    finalAudio.setLoop(false);
    finalAudio.setVolume(0.05);
    finalAudio.play();
    audio.pendingTime = audio.duration();
  }

  handleAnimation(delta) {
    if (this.safe) {
      this.playAnimation("sit");
    }
    else if (this.isShooting) {
      this.playAnimation("holding-both-shoot");
      this.playAudio("shoot");
    } else if (this.isRunning) {
      if (this.modifier > 0.75) {
        this.playAnimation("sprint");
        this.playAudio("footstep");
      } else {
        this.playAnimation("walk");
      }
    } else {
      this.playAnimation("idle");
    }
  }
}
