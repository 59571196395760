import * as THREE from "three";
import * as CANNON from "cannon";
import Entity from "./Entity.js";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";
import CollisionGroup from "../Utils/Collision.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";

export default class Letter extends Entity {
  constructor(experience, text) {
    const textGeometry = new TextGeometry(text, {
      font: experience.resources.items.roboto,
      size: 1.0 / 2.0,
      height: 0.2 / 2.0,
      curveSegments: 1,
      bevelEnabled: false,
    });

    const textMaterial = new THREE.MeshStandardMaterial({ color: 0xffffff });
    const textMesh = new THREE.Mesh(textGeometry, textMaterial);

    textMesh.receiveShadow = true;
    textMesh.castShadow = true;

    super(
      experience,
      textMesh,
      new CANNON.Body({
        mass: 1,
        position: new CANNON.Vec3(0, 0.0, 0),
        shape: new CANNON.Box(new CANNON.Vec3(0.18, 0.25, 0.07)),
      }),
      new THREE.Vector3(-0.22, -0.225, -0.05),
      new THREE.Quaternion()
    );
  }

  update(delta) {
    this.syncPosition(delta);
    this.syncRotation(delta);
  }

  syncPosition(delta) {
    const rotatedOffset = this.positionOffset
      .clone()
      .applyQuaternion(this.body.quaternion);
    this.model.position.copy(this.body.position).add(rotatedOffset);
  }

  syncRotation(delta) {
    this.model.quaternion.copy(this.body.quaternion);
  }
}
