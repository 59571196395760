import * as THREE from "three";
import * as CANNON from "cannon";

export default class InteractiveArea {
  constructor(
    experience,
    texture,
    size = new THREE.Vector2(1, 1),
    position = new THREE.Vector3(0, 0, 0),
    dialogTitle = "Default Title",
    dialogCaption = "Default Caption",
    url = "http://kaks.ch",
  ) {
    this.experience = experience;
    this.resources = this.experience.resources;
    this.texture = texture;
    this.position = position;
    this.dialogTitle = dialogTitle;
    this.dialogCaption = dialogCaption;
    this.url = url
    this.size = size;
    this.isInside = false;
    this.model = new THREE.Mesh(
      new THREE.PlaneGeometry(size.x, size.y),
      new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
      })
    );
    this.model.receiveShadow = true;
    this.position.y += 0.1;
    this.model.position.copy(this.position);
    this.model.rotateX(-Math.PI * 0.5);
    this.model.material.opacity = 1;

    this.text = new THREE.Mesh(
      new THREE.PlaneGeometry(2.0, 2.0),
      new THREE.MeshBasicMaterial({
        map: this.experience.resources.items.textWelcome,
        transparent: true,
      })
    );

    this.text.scale.set(1.0, 1.0, 1.0)
    this.text.rotateX(Math.PI / 180.0 * -60.0)
    //this.experience.world.scene.add(this.text)
  }

  update(delta, characterEntity) {
    const characterCoords = new THREE.Vector2(
      characterEntity.body.position.x,
      characterEntity.body.position.z
    );
    const coords = new THREE.Vector2(
      this.model.position.x,
      this.model.position.z
    );

    const minX = this.model.position.x - this.size.x / 2;
    const maxX = this.model.position.x + this.size.x / 2;
    const minZ = this.model.position.z - this.size.y / 2;
    const maxZ = this.model.position.z + this.size.y / 2;

    this.isInside =
      characterCoords.x >= minX &&
      characterCoords.x <= maxX &&
      characterCoords.y >= minZ &&
      characterCoords.y <= maxZ;

    if (this.isInside && !this.wasInside) {
      this.wasInside = true;
      characterEntity.onArea = this;
      const dialogElement = document.getElementById("dialog");
      const dialogTitleElement = document.getElementById('dialog-title');
      const dialogCaptionElement = document.getElementById('dialog-caption');

      dialogTitleElement.textContent = this.dialogTitle;
      dialogCaptionElement.textContent = this.dialogCaption;
      dialogElement.classList.remove("hidden");
      dialogElement.classList.add("visible");
    }

    this.model.material.opacity = THREE.MathUtils.lerp(
      this.model.material.opacity,
      this.isInside ? 1.0 : 0.1,
      delta * 10.0
    );

    if (this.wasInside && !this.isInside) {
      this.wasInside = false;
      characterEntity.onArea = null;
      const dialogElement = document.getElementById("dialog");
      dialogElement.classList.remove("visible");
      dialogElement.classList.add("hidden");
    }

    //this.text.position.copy(this.model.position.clone().add(new THREE.Vector3(0, 1.0, 0)))
    //this.text.material.opacity = (this.model.material.opacity - 0.25) / (1.0 - 0.25) * (1.0 - 0.0) + 0.0
  }
}
