import * as THREE from "three";
import Experience from "./Experience.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

export default class Camera {
  constructor(_options) {
    // Options
    this.experience = new Experience();
    this.config = this.experience.config;
    this.debug = this.experience.debug;
    this.time = this.experience.time;
    this.sizes = this.experience.sizes;
    this.targetElement = this.experience.targetElement;
    this.scene = this.experience.scene;
    this.target = null;

    // Set up
    this.mode = "debug"; // defaultCamera \ debugCamera

    this.setInstance();
    this.setModes();

    window.addEventListener("click", this.initializeAudio, { once: true });
  }

  initializeAudio() {
    if (!this.audio) {
      this.audio = new THREE.AudioListener();
    }
    if (this.audio.context.state === "suspended") {
      this.audio.context.resume();
    }
  }

  setTarget(newTarget) {
    this.target = newTarget;
  }

  setInstance() {
    // Set up
    this.instance = new THREE.PerspectiveCamera(
      75,
      this.config.width / this.config.height,
      0.1,
      150
    );
    this.instance.rotation.reorder("YXZ");

    this.audio = new THREE.AudioListener();
    this.instance.add(this.audio);
    this.targetZoom = 5;

    this.scene.add(this.instance);
  }

  switchToGameplayCoords() {
    this.modes.debug.instance.position.set(0, 4.5, 2.5);
    this.playing = true;
  }

  setModes() {
    this.modes = {};

    // Default
    this.modes.default = {};
    this.modes.default.instance = this.instance.clone();
    this.modes.default.instance.rotation.reorder("YXZ");

    // Debug
    this.modes.debug = {};
    this.modes.debug.instance = this.instance.clone();
    this.modes.debug.instance.rotation.reorder("YXZ");
    this.modes.debug.instance.position.set(0, 0.0, 0.0);

    this.modes.debug.orbitControls = new OrbitControls(
      this.modes.debug.instance,
      this.targetElement
    );

    this.modes.debug.orbitControls.minDistance = 3; // minimum zoom
    this.modes.debug.orbitControls.maxDistance = 6; // maximum zoom
    //this.modes.debug.orbitControls.enabled = false;
    this.modes.debug.orbitControls.enabled = true;
    this.modes.debug.orbitControls.screenSpacePanning = true;
    this.modes.debug.orbitControls.enableKeys = false;
    this.modes.debug.orbitControls.zoomSpeed = 1.0;
    this.modes.debug.orbitControls.enableDamping = true;

    this.modes.debug.orbitControls.enableRotate = false;
    //this.modes.debug.orbitControls.enableRotate = true;
    this.modes.debug.orbitControls.enablePan = false;
    this.modes.debug.orbitControls.enableDamping = false; // Optional, based on your preference
    this.modes.debug.orbitControls.enableKeys = false;

    // Keep zoom enabled
    this.modes.debug.orbitControls.enableZoom = true;

    this.modes.debug.orbitControls.update();
  }

  resize() {
    this.instance.aspect = this.config.width / this.config.height;
    this.instance.updateProjectionMatrix();

    this.modes.default.instance.aspect = this.config.width / this.config.height;
    this.modes.default.instance.updateProjectionMatrix();

    this.modes.debug.instance.aspect = this.config.width / this.config.height;
    this.modes.debug.instance.updateProjectionMatrix();
  }

  update(delta) {
    // Update debug orbit controls
    this.modes.debug.orbitControls.update();

    // Apply coordinates
    this.instance.quaternion.copy(this.modes[this.mode].instance.quaternion);
    this.instance.updateMatrixWorld(); // To be used in projection

    if (this.target) {
      // Define your lerp factor here, typically a small value like 0.05
      let lerpFactor = 0.075;

      if (this.playing)
        this.modes[this.mode].instance.position.z = THREE.MathUtils.lerp(
          this.modes[this.mode].instance.position.z,
          (this.instance.position.x > 3.0 && this.instance.position.z <= 4.0) ? 1.0 : 2.5,
          1 / 60.0 * 10.0
        );

        this.instance.position.lerp(
        this.target.position
          .clone()
          .add(this.modes[this.mode].instance.position),
        lerpFactor
      );

      let zoomLerpFactor = 0.1; // Adjust this factor as needed

      // Calculate new zoom level
      let currentZoom = this.modes.debug.orbitControls.object.zoom;
      let newZoom = THREE.MathUtils.lerp(
        currentZoom,
        this.targetZoom,
        zoomLerpFactor
      );

      // Apply new zoom level within limits
      newZoom = Math.max(
        this.modes.debug.orbitControls.minDistance,
        Math.min(newZoom, this.modes.debug.orbitControls.maxDistance)
      );

      // Update the camera zoom
      this.modes.debug.orbitControls.object.zoom = newZoom;
      this.modes.debug.orbitControls.object.updateProjectionMatrix();
    } else {
      this.instance.position.copy(this.modes[this.mode].instance.position);
    }
  }

  destroy() {
    this.modes.debug.orbitControls.destroy();
  }
}
